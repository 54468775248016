


const PREFILL_1 = `
# Nieuwe Wet seksuele misdrijven
Slachtoffers van aanranding en verkrachting kunnen vanaf 1 juli 2024 aangifte doen als duidelijk was dat ze geen seks wilden. Dat is het gevolg van de nieuwe Wet seksuele misdrijven. Ook seksuele intimidatie in het openbaar en het sturen van seksueel getinte berichten aan kinderen (sexchatting) zijn dan strafbaar.

**De Eerste Kamer  [stemde op 19 maart 2024 in met de Wet seksuele misdrijven](https://www.rijksoverheid.nl/onderwerpen/seksuele-misdrijven/nieuws/2024/03/20/nieuwe-wet-aanpak-seksuele-misdrijven-gaat-in-per-1-juli-2024). De wet gaat op 1 juli 2024 in.**

## Geen bewijs nodig van dwang bij aanranding en verkrachting
Met de nieuwe Wet seksuele misdrijven kunnen slachtoffers in meer gevallen aangifte doen van aanranding en verkrachting. Om verkrachting of aanranding aan te tonen, is door de nieuwe wet geen bewijs meer nodig dat iemand is gedwongen. Het is strafbaar als duidelijk was dat de ander geen seks wilde, maar iemand toch heeft doorgezet. Als er bewijs is van dwang, dan kan de dader een zwaardere straf krijgen.

### **Wet maakt verschil tussen opzet en schuld bij aanranding en verkrachting**
De nieuwe wet maakt onderscheid tussen opzet en schuld bij aanranding en verkrachting.

Zowel bij opzet als bij schuld, is er seksueel contact geweest terwijl iemand dat niet wilde. En waren duidelijke signalen aanwezig dat iemand het niet wilde die de dader heeft opgemerkt of had moeten opmerken.

Het verschil tussen opzet en schuld zit in de houding van de dader. Bij schuld gaat de dader er ten onrechte van uit dat de ander seksueel contact wil. Dit terwijl er signalen waren waaruit de dader had kunnen opmaken dat dit niet het geval was.

Er is sprake van opzet als de dader weet dat de ander geen seksueel contact wil. Of als de dader zich bewust is van die mogelijkheid, maar toch door gaat.

Bij gekwalificeerde opzetaanranding en -verkrachting gebruikt de dader dwang bij het ongewilde seksuele contact.

### **Nieuwe strafbare feiten voor aanranding en verkrachting**
De wet beschrijft verschillende strafbare feiten voor aanranding en verkrachting:

1.  Schuldaanranding
2.  Opzetaanranding
3.  Gekwalificeerde opzetaanranding
4.  Schuldverkrachting
5.  Opzetverkrachting
6.  Gekwalificeerde opzetverkrachting

### Maximale straffen voor aanranding en verkrachting
De maximale straffen voor verschillende delicten gaan per 1 juli 2024 omhoog. Zo ook bij aanranding en verkrachting en bij seksueel misbruik van kinderen.

| Seksuele misdrijven                       | Slachtoffer > 18 jaar | Slachtoffer 16-18 jaar (kwetsbaar) | Slachtoffer 12-16 jaar | Slachtoffer < 12 jaar |
|-------------------------------------------|-----------------------|------------------------------------|------------------------|-----------------------|
| **Schuldaanranding**                      | 2 jaar                | -                                  | -                      | -                     |
| **(Opzet)aanranding**                     | 6 jaar                | 6 jaar                             | 8 jaar                 | 10 jaar               |
| **Gekwalificeerde (opzet)aanranding**     | 8 jaar                | 8 jaar                             | 10 jaar en 8 maanden   | 13 jaar en 4 maanden  |
| **Schuldverkrachting**                    | 4 jaar                | -                                  | -                      | -                     |
| **(Opzet)verkrachting**                   | 9 jaar                | 9 jaar                             | 12 jaar                | 25 jaar               |
| **Gekwalificeerde (opzet)verkrachting**   | 12 jaar               | 12 jaar                            | 15 jaar                | 18 jaar               |

Bij schuldaanranding en schuldverkrachting wordt geen onderscheid gemaakt tussen opzet en schuld. Daarom staat in tabel hiervoor geen maximale straf.

## Seksuele intimidatie in openbaar strafbaar
Seksuele intimidatie in het openbaar is met de nieuwe wet strafbaar. Van seksuele intimidatie is sprake als iemand bijvoorbeeld op straat of in de supermarkt als seksueel object wordt benaderd. Bijvoorbeeld als iemand achterna wordt gelopen met seksueel getinte opmerkingen. Veel mensen voelen zich hierdoor onveilig en gaan bepaalde straten ontwijken of zich anders kleden. Ook seksuele intimidatie online, bijvoorbeeld op social media, is strafbaar.

## Seksueel benaderen van kinderen (sexchatting) strafbaar
Volgens de nieuwe wet is het verboden om kinderen onder de 16 jaar seksueel te benaderen. Bijvoorbeeld door online seksueel getinte sms’jes of mails te sturen of als een buurman een buurmeisje intieme vragen stelt. Dat is ook verboden bij kinderen van 16 en 17 jaar die in een kwetsbare positie zitten. Bijvoorbeeld kinderen met een verstandelijke handicap of een psychische stoornis.

De politie kan door de nieuwe wet sneller optreden om seksueel misbruik van kinderen te voorkomen.

## Bewijzen van seksuele misdrijven kan lastig zijn
Seksuele misdrijven bewijzen kan ook met de nieuwe wet lastig blijven, omdat er meestal geen getuigen bij zijn. Als een verdachte ontkent en er zijn geen getuigen, dan is ander bewijs nodig. Bijvoorbeeld sporen van sperma op het lichaam of op kleding, camerabeelden of tekstberichten.
`

export const PREFILL_2 = `
# Nieuwe wet aanpak seksuele misdrijven gaat in per 1 juli 2024
Nieuwsbericht | 20-03-2024 | 07:00

Slachtoffers van seksueel geweld en seksueel grensoverschrijdend gedrag worden vanaf 1 juli dit jaar strafrechtelijk beter beschermd. De Eerste Kamer heeft deze week ingestemd met een brede herziening van wetgeving middels de Wet seksuele misdrijven van minister Yeşilgöz-Zegerius van Justitie en Veiligheid. Slachtoffers kunnen straks in meer gevallen aangifte doen van verkrachting en aanranding. Ook seksuele intimidatie in het openbaar wordt strafbaar; dit kan zich afspelen op straat, maar bijvoorbeeld ook op publieke websites en social media. Daarnaast wordt sexchatting strafbaar, gaan de maximumstraffen voor sommige delicten omhoog en wordt de wet digitaal meer bij de tijd gebracht. Uitgangspunt in de nieuwe wet is dat seksueel geweld en seksueel grensoverschrijdend gedrag offline en online even strafwaardig is.

> Minister Yeşilgöz: "Met deze wet stellen we een duidelijk norm: seks hoort altijd vrijwillig en gelijkwaardig te zijn. Iemand is strafbaar bezig als diegene weet – of had moeten weten – dat de ander niet wil, maar toch doorgaat. De huidige wetgeving ten aanzien van seksueel geweld sloot niet meer aan bij wat we als samenleving acceptabel gedrag vinden en wat niet. Seksueel misbruik heeft indringende en langdurige gevolgen op het leven van mensen. Ook seksuele intimidatie moet harder worden aangepakt. Het is onacceptabel dat straten gemeden worden en dat mensen – vaak vrouwen en kinderen – zich niet veilig kunnen bewegen op internet en op social media uit angst voor seksueel ongewenst gedrag van anderen."

## Delicten verkrachting en aanranding
Om verkrachting of aanranding aan te tonen is het niet meer nodig om te bewijzen dat er sprake was van dwang, doordat bijvoorbeeld geweld was toegepast of was gedreigd met geweld. Iemand is strafbaar als diegene seksueel contact doorzet, terwijl er duidelijke signalen zijn dat de ander geen seks wil. Dwang, is voortaan wel een strafverzwarende factor, maar niet langer een vereiste voor een veroordeling voor verkrachting of aanranding. Degene die seksueel contact initieert, moet alert zijn of de ander hetzelfde wil. Als dit niet duidelijk is, moet je bij de ander nagaan hoe diegene erin staat.

Duidelijke signalen van een ontbrekende wil zijn bijvoorbeeld expliciet verbaal of fysiek afhoudend gedrag, maar kunnen ook bestaan uit non-verbale signalen of een passieve opstelling, zoals het uit angst bevriezen van het lichaam. Het blijft bij seksuele misdrijven zo dat het bewijs in concrete zaken lastig te leveren kan zijn. Vaak is sprake van een één-op-één situatie zonder getuigen. Als de verdachte dan ontkent, gaat het om het woord van de één tegen de ander. Steunbewijs is dan noodzakelijk, zoals sporen op het lichaam, camerabeelden en bijvoorbeeld tekstberichten. Verder is belangrijk dat in de nieuwe wet verkrachting niet kan verjaren. Slachtoffers kunnen zelf bepalen of en wanneer zij aangifte doen.

## Online en offline
Met het toenemend gebruik van internet, sociale media en smartphones is er meer online seksueel contact. Dit maakt het mogelijk seksuele misdrijven ook op afstand te plegen. Vooral kinderen zijn kwetsbaar voor online seksueel misbruik. Met de Wet seksuele misdrijven wordt bijvoorbeeld het zogenoemde sexchatting strafbaar: het seksueel benaderen van kinderen beneden de leeftijd van 16, maar ook het seksueel benaderen van kinderen van 16 en 17 jaar in een kwetsbare positie. Bij sexchatting hoeft geen sprake te zijn van het doen van een voorstel tot een ontmoeting voor seksuele doeleinden. Dit in tegenstelling tot grooming, wat nu al strafbaar is. Hierdoor kan in de voorfase van daadwerkelijk seksueel misbruik eerder worden opgetreden.

## Seksuele intimidatie
Ook wordt seksuele intimidatie in het openbaar strafbaar als overtreding. Door seksueel intimiderend gedrag voelen veel mensen zich onveilig. Zo kan het voorkomen dat iemand bepaalde straten ontwijkt of zich anders gaat kleden. Ook online komt het voor: bijvoorbeeld op social media of chatsites, dat seksueel intimiderende opmerkingen tegen anderen worden gemaakt. Dit heeft vaak een grote impact op het gevoel van veiligheid of waardigheid van mensen.

## Hogere straffen
Naast nieuwe strafbaarstellingen worden ook straffen verhoogd voor een aantal seksuele misdrijven. Bijvoorbeeld verkrachting met een kind jonger dan 12 jaar. Hiervoor gaat de gevangenisstraf naar maximaal 15 jaar. Is het kind tussen de 12 en 16 jaar dan wordt de gevangenisstraf maximaal 12 jaar. Voor kinderpornografie wordt het strafmaximum verhoogd naar 6 jaar gevangenisstraf.

## Uitvoering
Voor een goede uitvoering van de nieuwe wet is circa 1,5 jaar geleden al met de betrokken partijen , zoals de politie en het openbaar ministerie, een zogenoemd implementatietraject opgezet, om te faciliteren dat zij goed op de nieuwe wetgeving zijn voorbereid en zij straks met de nieuwe wetgeving in de hand zaken ook echt beter kunnen oppakken. Denk hierbij aan het maken van werkinstructies, aanpassingen van ICT en opleiding. Er worden bijvoorbeeld zo’n 25.000 politieambtenaren opgeleid in de nieuwe wet, zodat zij straks op 1 juli 2024 goed zijn voorbereid. Dit geldt niet alleen specifiek voor zedenrechercheurs, maar bijvoorbeeld ook voor intakemedewerkers op politiebureaus.

## Doelgroepen die door de regeling worden geraakt
Iedereen. In het bijzonder: slachtoffers van seksueel grensoverschrijdend gedrag en hun naasten, verdachten, veroordeelden, organisaties in de strafrechtketen, hulpverleners en andere relevante betrokkenen.

## Verwachte effecten van de regeling voor de doelgroepen
- Duidelijke normstelling over seksueel grensoverschrijdend gedrag, die beter aansluit bij de huidige maatschappelijke opvattingen over onvrijwillig seksueel contact;  
- Betere bescherming voor slachtoffers van seksueel grensoverschrijdend gedrag;  
- Slachtoffers kunnen in meer situaties aangifte doen van een strafbaar feit;  
- Organisaties in de strafrechtketen, zoals politie en openbaar ministerie, worden in staat gesteld gerichter en effectiever op te treden tegen veelvoorkomend strafbaar gedrag;  
- Meer mogelijkheden om een, bij het feit passende, hogere straf op te leggen;  
- Afschrikwekkende werking die uitgaat van de nieuwe strafbaarstellingen en strafverhogingen.

## Waarop kunt u reageren
Het wetsvoorstel betreft een belangrijk maatschappelijk onderwerp, omdat het raakt aan de binnen onze samenleving levende opvattingen over wanneer seksuele grenzen worden overschreden. In 2020 vond consultatie plaats over een voorontwerp. De reacties hierop zijn verwerkt en het wetsvoorstel is aangepast. Deze consultatie maakt het mogelijk om in debat te blijven over de nieuwe wetgeving en de invulling van de strafrechtelijke normstelling.
`;

const PREFILL_3 = `
# Memorie van toelichting; kamerstukken II 36.222, 2022/23, nr. 3

Inleiding:

Seksueel grensoverschrijdend gedrag - zoals seksueel geweld, seksueel kindermisbruik en seksuele intimidatie - komt veel te vaak voor en kan ernstige en langdurige gevolgen voor slachtoffers hebben. Uit cijfers afkomstig uit de prevalentiemonitor Huiselijk en Seksueel Geweld van eind 2020 blijkt dat 1.6 miljoen mensen (van zestien jaar en ouder) aangaven in de twaalf maanden voorafgaand aan het onderzoek slachtoffer te zijn geweest van een vorm van seksuele grensoverschrijding, variërend van fysiek seksueel geweld tot niet fysieke seksuele intimidatie tot online seksuele intimidatie.

Bij ongeveer 470.000 personen ging het om fysiek seksueel geweld, waarbij lichamelijk contact plaatsvond, variërend van ongewenste aanrakingen tot verkrachting. Uit onderzoek van Rutgers blijkt dat 11% van de meisjes en 2% van de jongens tussen de twaalf en 25 jaar wel eens iets seksueels gedaan of toegelaten hebben dat ze niet wilden. 

Seksuele misdrijven zijn strafbaar gesteld in het Wetboek van Strafrecht. De strafwetgeving weerspiegelt in belangrijke mate de heersende seksuele moraal en normeert gedrag dat vanuit maatschappelijk oogpunt bezien bestraffing verdient. Als gevolg van verschillende maatschappelijke, technologische en juridische ontwikkelingen is de huidige zedenwetgeving verouderd geraakt. Mede onder invloed van internationale ontwikkelingen zijn de afgelopen jaren de opvattingen binnen onze samenleving over seksuele grensoverschrijding strikter geworden en zijn de grenzen over welk gedrag strafwaardig is verschoven. Daarnaast is de maatschappelijke aandacht voor en het bewustzijn van het leed dat slachtoffers van seksueel grensoverschrijdend gedrag wordt aangedaan toegenomen. De huidige strafwet schiet als gevolg van voornoemde ontwikkelingen tekort in de strafrechtelijke bescherming tegen seksueel geweld, onder meer met betrekking tot de strafbaarstelling van verkrachting.

Een actueel en effectief strafrechtelijk instrumentarium is van wezenlijk belang voor een veilige samenleving. Voor een adequate strafrechtelijke vertaling van de sociale normen met betrekking tot seksueel grensoverschrijdend gedrag en gericht strafrechtelijk optreden tegen dit gedrag is het nodig en wenselijk de strafwetgeving bij de tijd te brengen. 

Hiermee wordt de strafrechtelijke normstelling ook meer in lijn gebracht met de toepasselijke internationale verplichtingen. Wetswijziging is hiervoor het passende instrument. Dit vloeit voort uit het in artikel 7 van het Europees Verdrag voor de Rechten van de Mens (EVRM), artikel 16 van de Grondwet en artikel 1 van het Wetboek van Strafrecht vastgelegde legaliteitsbeginsel: de wet bepaalt welke gedragingen strafbaar zijn. Oplossingen binnen het bestaande strafrechtelijke kader, zoals intensivering van de handhaving, zijn onvoldoende effectief en volstaan niet, omdat uitsluitend via wetswijziging in uitbreiding van de strafrechtelijke aansprakelijkheid kan worden voorzien. Aanscherping en verduidelijking van de normstelling inzake seksuele misdrijven kunnen niet aan de rechtspraktijk worden overgelaten. 

Het is de taak van de wetgever om te voorzien in actuele, bij de maatschappelijke realiteit aansluitende, heldere en toegankelijke strafwetgeving, waardoor voorzienbaar is welk handelen of nalaten waarmee een norm wordt overschreden kan leiden tot het opleggen van een straf. 

Dit wetsvoorstel vervangt in Boek 2 van het Wetboek van Strafrecht de huidige Titel XIV Misdrijven tegen de zeden door een nieuwe Titel XIV Seksuele misdrijven. Hierin wordt de strafrechtelijke aansprakelijkheid voor verschillende vormen van seksueel grensoverschrijdend gedrag opnieuw vastgesteld en begrensd. De strafrechtelijke bescherming tegen seksueel geweld (aanranding en verkrachting) wordt uitgebreid en gemoderniseerd. Zowel de inhoud van de strafbepalingen als de wettelijke structuur wordt gemoderniseerd. Hiermee wordt tevens gevolg gegeven aan de aanbeveling uit het WODC-onderzoek “Herziening van de zedendelicten?” om de huidige zedenwetgeving technisch-juridisch te herzien en de wettelijke systematiek te vereenvoudigen en verduidelijken.

De nieuwe wetgeving vormt een noodzakelijke vervanging van en aanvulling op het bestaande strafrechtelijke instrumentarium en past bij het uitgangspunt dat strafrecht de rol heeft van ultimum remedium. Bij seksueel grensoverschrijdend gedrag gaat het om zodanige aantastingen van de persoonlijke levenssfeer en inbreuken op de lichamelijke en geestelijke integriteit dat handhaving van normschendingen niet of niet alleen door andere sancties dan strafrechtelijke sancties kan plaatsvinden. 

Het wetsvoorstel heeft oog voor zowel de bescherming van slachtoffers als het waarborgen van de rechten en positie van plegers van seksuele misdrijven. De nieuwe wetgeving biedt genoegdoening voor het vaak verwoestende en langdurige leed bij slachtoffers van seksuele misdrijven, maar ook een legitimatie om diep in te grijpen in levens van plegers door hen met behulp van (zware) sanctiemogelijkheden tot ander gedrag aan te zetten. Met het oog op de voorzienbaarheid van het risico op strafrechtelijke aansprakelijkheid bevat het wetsvoorstel duidelijk geformuleerde strafbepalingen, waarin het strafrechtelijk verwijt aan de pleger tot uitdrukking wordt gebracht. Hoe ernstiger het verwijt, des te zwaarder is de wettelijke kwalificatie van het strafbare gedrag. 

De aan een strafbare gedraging gekoppelde strafsoort en -hoogte zijn evenredig aan de aard en ernst van het strafbare gedrag. De - in vergelijking met andere misdrijven - hoge wettelijke strafmaxima in het wetsvoorstel geven uitdrukking aan de ernstige schade die slachtoffers van seksuele misdrijven wordt aangedaan. 

Doel van het wetsvoorstel is een adequate en herkenbare strafrechtelijke reactie mogelijk te maken op seksueel grensoverschrijdend gedrag dat in de hedendaagse en digitaliserende samenleving als strafwaardig wordt ervaren. Als gevolg hiervan wordt de veiligheid van eenieder in Nederland vergroot. Met een actueel wettelijk kader dat aansluit bij de maatschappelijke realiteit worden slachtoffers beter beschermd. Zij kunnen in meer situaties aangifte doen. Politie en OM zullen meer mogelijkheden hebben om op te treden tegen strafbaar gedrag. Bij constatering van een strafbaar feit kunnen opsporing en vervolging plaatsvinden. En bij bewezenverklaring kan een passende straf of maatregel worden opgelegd. Daarnaast geeft het wetsvoorstel een duidelijk signaal af aan (potentiële) daders dat seksueel grensoverschrijdend gedrag niet acceptabel is en streng wordt bestraft. Daarmee gaat van dit wetsvoorstel ook een onmiskenbare normerende en preventieve werking uit. 

Om de doorwerking van de nieuwe strafrechtelijke normstelling binnen de samenleving te bevorderen zal de invoering van de nieuwe wetgeving gepaard gaan met initiatieven op het terrein van voorlichting en bewustwording over wanneer seksueel gedrag strafwaardig wordt. Hierbij zal nadrukkelijk de samenwerking worden gezocht met maatschappelijke organisaties. Parallel aan het wetgevingstraject wordt binnen het ministerie van Justitie en Veiligheid (JenV) een programma opgestart om de implementatie van de nieuwe wetgeving in goede banen te leiden. 

In het voorjaar van 2020 heeft (internet)consultatie plaatsgevonden over een voorontwerp van het wetsvoorstel seksuele misdrijven. Hierop volgde in het voorjaar van 2021 (internet)consultatie over een aangepast wetsvoorstel waarin de reacties op het voorontwerp waren verwerkt. De vele en waardevolle reacties in beide consultatierondes hebben laten zien dat de strafrechtelijke normstelling inzake seksuele misdrijven een onderwerp is dat sterk leeft binnen onze samenleving. Uit de ontvangen adviezen en reacties kan worden afgeleid dat er brede maatschappelijke consensus is over de noodzaak van modernisering van de strafwet om seksueel grensoverschrijdend gedrag beter aan te pakken. 

2. Relevante ontwikkelingen 

2.1. Veranderde opvattingen over de strafwaardigheid van seksueel grensoverschrijdend gedrag 

Seksueel grensoverschrijdend gedrag is een wijdverbreid en hardnekkig probleem, waarvan veel mensen in Nederland slachtoffer worden. Uit de prevalentiemonitor Huiselijk en Seksueel Geweld 2020 blijkt dat circa 470.000 hebben personen aangegeven in de twaalf maanden voorafgaand aan het onderzoek slachtoffer te zijn geweest van fysiek seksueel geweld, variërend van ongewenste aanrakingen tot expliciete seksuele handelingen als seksuele penetratie. Ongewenst aangeraakt worden kwam het vaakst voor, gevolgd door ongewenst zoenen. 

Vrouwen waren vaker slachtoffer van fysiek seksueel geweld dan mannen, en jongeren - met name 18- tot 24-jarigen - vaker dan ouderen. Jonge vrouwen maakten het vaakst fysiek seksueel geweld mee; 22% van de 18- tot 24- jarige vrouwen gaf aan in de afgelopen twaalf maanden hiervan slachtoffer te zijn geweest. Dit is vier keer zo veel als hun mannelijke leeftijdsgenoten. In de meeste gevallen (85%) was de pleger iemand van buiten de huiselijke kring, in 40% van de gevallen betrof het een onbekende. Bijna 80% van de slachtoffers ondervond seksueel geweld van een man (of meerdere mannen), 15% van een vrouw (of meerdere vrouwen). Bij 8% van de slachtoffers betrof het partnergeweld. In 40% van de gevallen gaven slachtoffers aan dat het seksueel geweld gepleegd werd door een onbekende. In 22% van de gevallen kenden de slachtoffers de pleger uit het uitgaansleven. Verder werden nieuwe dates (10%) of goede vrienden (10%) vaak genoemd. In 3% van de gevallen werd het seksuele geweld gepleegd door iemand in een hogere hiërarchische positie, zoals een leidinggevende, docent, trainer of religieus leider.

Seksueel grensoverschrijdend gedrag maakt inbreuk op de persoonlijke levenssfeer en tast de lichamelijke en geestelijke integriteit van slachtoffers aan. De bescherming van deze grondrechten wordt op zowel internationaal niveau als via nationale wet- en regelgeving geborgd. In internationaal verband is sinds enige tijd sprake van een tendens tot striktere normstelling inzake seksueel grensoverschrijdend gedrag. De #Metoo-beweging heeft wereldwijd en ook in Nederland op onmiskenbare wijze aan het licht gebracht hoe vaak seksueel grensoverschrijdend gedrag voorkomt, welke gedaantes dit aanneemt en hoeveel leed het toebrengt aan slachtoffers. 

Gebeurtenissen in onder andere de sportsector, theater- en televisiewereld in Nederland hebben dit opnieuw duidelijk gemaakt. Slachtoffers voelen zich bedreigd, geïntimideerd en vernederd. De fysieke en psychische schade die slachtoffers ervaren is vaak ernstig en indringend en kan langdurige en verwoestende gevolgen hebben voor hun levens. Onderzoek heeft aangetoond dat bijna alle slachtoffers van seksueel grensoverschrijdend gedrag symptomen van posttraumatische stress ervaren in de periode na de gebeurtenis. Een derde van de slachtoffers vertelt het ondergane seksuele geweld aan niemand. Bij kinderen kan seksueel grensoverschrijdend gedrag op een latere leeftijd leiden tot drugs- en alcoholmisbruik en seksueel riskant gedrag. Tevens kan seksueel grensoverschrijdend gedrag sociale en economische gevolgen hebben.

Veel ons omringende landen hebben recent, mede onder invloed van het Verdrag van de Raad van Europa inzake het voorkomen en bestrijden van geweld tegen vrouwen en huiselijk geweld (Verdrag van Istanbul) hun strafwetgeving met betrekking tot seksueel grensoverschrijdend gedrag herzien of hebben nieuwe wetgeving in voorbereiding. 

Artikel 36 van dit Verdrag - dat in 2016 voor Nederland in werking is getreden - verplicht tot het strafbaar stellen van seksuele handelingen zonder wederzijds goedvinden. En op grond van artikel 40 van dit verdrag dient elke vorm van ongewenst verbaal, non-verbaal of fysiek seksueel getint gedrag dat de waardigheid van een persoon schendt, onderworpen te zijn aan sancties. In het in januari 2020 gepubliceerde rapport met aanbevelingen moedigt de Groep van deskundigen inzake actie tegen geweld tegen vrouwen en huiselijk geweld (GREVIO) die toezicht houdt op de uitvoering van het verdrag de Nederlandse autoriteiten aan de bepalingen in het Wetboek van Strafrecht inzake seksueel geweld spoedig te herzien en erop toe te zien dat passende sancties worden ingevoerd voor alle seksuele handelingen die plaatsvinden zonder toestemming van het slachtoffer. 

Seksuele autonomie en seksuele gelijkwaardigheid zijn belangrijke thema’s in onze samenleving. Mede onder invloed van voornoemde internationale ontwikkelingen en ook in het kader van de totstandkoming van dit wetsvoorstel is de afgelopen periode in Nederland een maatschappelijke discussie op gang gekomen over de strafbaarheid van seksueel grensoverschrijdend gedrag. Het maatschappelijk gesprek maakt duidelijk dat de grenzen zijn opgeschoven van wat binnen onze samenleving als acceptabel en toelaatbaar seksueel gedrag wordt beschouwd. Ook is er meer aandacht gekomen voor de positie van slachtoffers. Sneller komen de grenzen van wat als strafwaardig gedrag wordt gezien in beeld en grensoverschrijdend gedrag wordt sterker afgekeurd. Dit geldt zowel voor ernstiger vormen van seksuele grensoverschrijding, zoals seksueel geweld, dat strafbaar is gesteld als aanranding en verkrachting. 

2.2. 	Tekortschietende strafrechtelijke bescherming tegen seksueel geweld
Uit de maatschappelijke discussie over seksueel geweld komt heel duidelijk naar voren dat de sociale norm is dat seksueel contact vrijwillig en gelijkwaardig behoort te zijn en dient te berusten op wederzijds goedvinden. Onvrijwillige en ongelijkwaardige seksuele interactie is altijd strafwaardig, ongeacht of sprake is van gebruik van dwang, geweld of bedreiging door de pleger dan wel of het slachtoffer zich heeft verzet. Er is brede consensus dat niet het doorbreken van de wil bij de ander, maar een ontbrekende wil met betrekking tot het seksuele contact bij het ander uitgangspunt voor strafrechtelijke aansprakelijkheid dient te zijn. 

Zo komt uit het rapport “Hoe denken Nederlanders over verkrachting”, dat is opgesteld in opdracht van Amnesty International, naar voren dat 88 tot 90% van de Nederlanders vindt dat iedere penetratie zonder instemming verkrachting is. Hierbij speelt nadrukkelijk mee dat een meerderheid van slachtoffers van onvrijwillige seks als gevolg van een door angst gedreven reactie van hun lichaam zich niet kan uiten of verzetten. Ook blijkt uit het onderzoek dat van de 499 aangiftes in 102 zaken de verdachte werd veroordeeld voor verkrachting (20,4 % ten opzichte van het aantal aangiftes).


Uit ander in opdracht van Amnesty International uitgevoerd onderzoek onder studenten in het hoger onderwijs blijkt dat in veruit de meeste gevallen van seksuele penetraties zonder instemming die plaatsvinden tijdens de studententijd, de pleger geen geweld gebruikte of daarmee dreigde. Meestal ging de pleger door zonder te checken of de ander wilde, werd aangedrongen op seksueel contact, werd genegeerd wat de ander verbaal dan wel non-verbaal aangaf of werd het seksueel contact doorgezet terwijl de ander niet meer reageerde of bevroor. Hetzelfde beeld komt naar voren uit de prevalentiemonitor Huiselijk en Seksueel Geweld. Hierin is gevraagd aan slachtoffers van seksueel geweld welke pressiemiddelen de pleger gebruikte. In de meeste gevallen ging het om aandringen of zeuren. Dit werd door bijna twee derde van de slachtoffers genoemd. Een andere veelgenoemde reactie was boos worden. Bij ruim één op de vijf slachtoffers werd er misbruik van gemaakt dat het slachtoffer onder invloed was van alcohol of drugs. Ruim één op de tien werd gedrogeerd. Ook werd gebruik van geweld of chantage of dreiging met geweld genoemd.

In de prevalentiemonitor Huiselijk en Seksueel Geweld worden door slachtoffers van seksueel geweld naast expliciete (verbale) tegenreacties, zoals schreeuwen of duidelijk ‘nee’ zeggen, overhalen te stoppen, duwen of vechten, als veelvoorkomende reacties genoemd het instinctief wegdraaien van het hoofd of lichaam, wachten tot het voorbij is en bevriezing van het lichaam. Deze laatste reactie, die bekend is uit de (neuro)biologie, wordt ook wel ‘tonische immobiliteit’ genoemd. Het betreft-netalsvluchtenofvechten-eendoorangstgedrevennatuurlijkereactie van het lichaam op een bedreigende situatie. In deze fysieke toestand van verstijving is het niet mogelijk zich te uiten en/of fysiek verzet te bieden. 
De huidige strafwetgeving weerspiegelt onvoldoende de actuele maatschappelijke opvattingen over de strafbaarheid van seksueel geweld. In de delicten aanranding (afgedwongen ontuchtige handelingen) en verkrachting (afgedwongen handelingen die bestaan uit of mede bestaan uit het seksueel binnendringen van het lichaam) (de artikelen 242 en 246 Sr) staat niet de wederkerigheid van seksueel contact centraal, maar het dwingen van de ander. Het beeld dat uit de jurisprudentie over aanranding en verkrachting naar voren komt is dat de juridische drempel voor strafbaarheid nu soms niet wordt gehaald, terwijl wel sprake is van onvrijwilligheid aan de zijde van het slachtoffer. 

Voor een bewezenverklaring van dwang in de zin van deze delicten is vereist dat komt vast te staan dat de verdachte opzettelijk heeft veroorzaakt dat het slachtoffer seksuele handelingen tegen de (kenbare) wil ondergaat. Bewezen dient te worden dat de seksuele handelingen voor het slachtoffer niet of nauwelijks te vermijden zijn geweest. Hiervoor is enige vorm van verzet nodig of ten minste een bij het slachtoffer bestaande handelingsonvrijheid die de afwezigheid van verzet verklaart, zoals onvrijheid veroorzaakt door geweld of een bedreigende sfeer. Bovendien moet kunnen worden bewezen dat het opzet van de dader was gericht, ten minste in voorwaardelijke vorm, op zowel de onvrijwilligheid als de onvermijdbaarheid aan de zijde van het slachtoffer. Een kenbaar “nee” van een slachtoffer wordt voor bewezenverklaring niet zonder meer voldoende geacht. Dit geldt evenzeer voor situaties waarin een slachtoffer fysiek bevriest van angst en zich daardoor niet kan uiten of verzetten. 

De huidige strafwet biedt aldus onvoldoende bescherming aan slachtoffers die in een situatie verkeren waarin ze geen seksueel contact willen, maar waarmee geen of onvoldoende rekening wordt gehouden door de ander. De mogelijkheden voor strafrechtelijk optreden tegen seksueel geweld schieten tekort. Omdat de juridische lat voor strafbaarheid bij aanranding en verkrachting te hoog ligt gaan plegers vrijuit in plaats dat grensoverschrijdend gedrag wordt vervolgd, bestraft en gecorrigeerd. Slachtoffers krijgen onvoldoende genoegdoening van het leed dat hun wordt aangedaan. 

Daarnaast geeft de huidige strafwet een onvoldoende helder signaal af dat alle vormen van seksueel geweld onacceptabel zijn en het strafbare feit aanranding of verkrachting opleveren. Uitsluitend bij dwang of verzet wordt onvrijwillig of ongelijkwaardig seksueel contact strafrechtelijk als aanranding of verkrachting gekwalificeerd. De huidige wettelijke kwalificatie van verkrachting doet niet op gelijke wijze recht aan mannelijke en vrouwelijke slachtoffers. Afgedwongen seksueel binnendringen van het lichaam wordt nu uitsluitend als verkrachting gekwalificeerd als een slachtoffer wordt gedwongen tot het ondergaan van seksuele penetratie van het lichaam en degene die de seksuele penetratie verricht degene is die de dwang uitoefent. Overige, naar hun aard en ernst even strafwaardige vormen van afgedwongen seksueel binnendringen van het lichaam, zoals seksuele penetratie van het eigen lichaam of van het lichaam van een ander, al dan niet op afstand, worden wettelijk gekwalificeerd als het met een lagere maximumstraf bedreigde misdrijf aanranding.

In het licht van voornoemde ontwikkelingen is er aanleiding de strafrechtelijke bescherming bij de tijd te brengen en te verbeteren, zodat beter opgetreden kan worden tegen seksueel geweld. Hiervoor is het nodig en wenselijk de huidige delicten aanranding en verkrachting te vervangen door nieuwe delicten en de drempel voor strafbaarheid wegens aanranding en verkrachting te verlagen. En zo tevens de strafwet in lijn te brengen met artikel 36 van het Verdrag van Istanbul. 
 
2.5 	Aanbeveling tot juridisch-technische herziening van de zedenwetgeving 
Onder invloed van internationale wet- en regelgeving is de huidige zedentitel in het Wetboek van Strafrecht de afgelopen jaren vaak gewijzigd. Het veelvoud aan wijzigingen heeft bij de implementatie van de richtlijn 2011/93/EU van het Europees Parlement en de Raad ter bestrijding van seksueel misbruik en seksuele uitbuiting van kinderen en kinderpornografie, en ter vervanging van Kaderbesluit 2004/68/JBZ van de Raad (PbEU L 335) (hierna: richtlijn 2011/93/EU) geleid tot kritische opmerkingen van de Afdeling advisering van de Raad van State over de overlap tussen verschillende strafbepalingen en het ontbreken van evenwicht tussen de strafmaxima.38/39 Hierin is aanleiding gezien voor de uitvoering van een WODC-onderzoek naar een herziening van de zedendelicten. In het onderzoek, dat is verricht door de Rijksuniversiteit Groningen, stond de volgende onderzoeksvraag centraal: Geeft Titel XIV, Boek II van het Wetboek van Strafrecht in termen van samenhang, complexiteit en normstelling aanleiding tot de conclusie dat deze titel moet worden herzien? Het onderzoeksrapport, getiteld “Herziening van de zedendelicten?” bevatte de aanbeveling om de huidige zedenwetgeving uit een oogpunt van samenhang, complexiteit en normstelling, integraal te herzien en de toepasbaarheid hiervan voor praktijkjuristen te verbeteren. In de beleidsreactie op het onderzoek is deze aanbeveling overgenomen.40 
In het licht hiervan en tegen de achtergrond van de overige relevante ontwikkelingen wordt het nodig en wenselijk geacht de huidige zedentitel in het Wetboek van Strafrecht te vervangen door een geheel nieuwe Titel Seksuele misdrijven en hierin zowel de inhoud van de strafrechtelijke normstelling als de wettelijke structuur bij de tijd te brengen. In de navolgende onderdelen van deze memorie worden de hoofdlijnen van de nieuwe wetgeving toegelicht. 


Hoofdlijnen van het wetsvoorstel
Dit wetsvoorstel vervangt in het Tweede Boek van Wetboek van Strafrecht de huidige Titel XIV “Misdrijven tegen de zeden” door een nieuwe Titel XIV “Seksuele misdrijven”. Hierin wordt, met inachtneming van de toepasselijke internationale verplichtingen, de strafrechtelijke aansprakelijkheid geregeld voor verschillende vormen van seksueel grensoverschrijdend gedrag die een ernstige aantasting vormen van de lichamelijke en seksuele integriteit. In de nieuwe Titel Seksuele misdrijven, die is toegesneden op veelvoorkomende verschijningsvormen, wordt beschreven welk gedrag onder welke omstandigheden tot strafrechtelijke aansprakelijkheid leidt. Als seksuele misdrijven worden aangemerkt verschillende gedragingen die worden gekenmerkt door het feit dat sprake is van onvrijwillig, ongelijkwaardig of ongewenst seksueel contact. Uitgangspunt is dat gedragingen in de offline wereld en in de online wereld die op soortgelijke wijze een aantasting vormen van de lichamelijke en seksuele integriteit van een slachtoffer even strafwaardig zijn. Op onderdelen wordt de omvang van de strafrechtelijke bescherming uitgebreid en gemoderniseerd. Dit wordt in de navolgende onderdelen van deze memorie verder toegelicht. 
De nieuwe titel voorziet in bij de maatschappelijke realiteit aansluitende, heldere en toegankelijke strafbepalingen en bevat verschillende van elkaar te onderscheiden delicten die een duidelijke wettelijke ingang voor strafbaarheid bieden. Achtereenvolgens worden strafbaar gesteld: schuldaanranding, opzetaanranding en gekwalificeerde opzetaanranding, schuldverkrachting, opzetverkrachting en gekwalificeerde opzetverkrachting, aanranding en gekwalificeerde aanranding en verkrachting en gekwalificeerde verkrachting in drie verschillende leeftijdscategorieën, seksuele benadering van kinderen, kinderpornografie, het bijwonen van een kinderpornografische voorstelling, aanstootgevend gedrag, dierenpornografie en het verrichten van seksuele handelingen met een dier. Naast strafbaarstellingen bevat de nieuwe titel bepalingen waarin definities, strafverzwaringsgronden en de bijkomende straffen zijn opgenomen. De nieuwe strafbepalingen zijn onderling afgestemd wat betreft formulering en wat betreft strafhoogte, waardoor een samenhangend en overzichtelijk geheel ontstaat. 
Drie huidige misdrijven tegen de zeden die minder of geen verband houden met de bescherming van de lichamelijke en seksuele integriteit, maar meer met het bestrijden van publieke wanordelijkheden (pornografie, toedienen van een bedwelmende drank en schadelijke visuele weergave tonen aan kinderen) keren niet terug in de nieuwe titel en worden in dit wetsvoorstel overgeheveld naar het Tweede Boek van het Wetboek van Strafrecht Titel V Misdrijven tegen de openbare orde. Die nieuwe titel bevat evenmin het misdrijf van vormen van kinderverwaarlozing (artikel 253 Sr); de daarin vervatte gedragingen vallen al onder het bereik van mensenhandel (artikel 273f Sr). De enige overtreding betreffende de zeden is omdat het geen verband houdt met de bescherming van de lichamelijke en seksuele integriteit verplaatst naar het Derde Boek van het Wetboek van Strafrecht, Titel II Overtredingen betreffende de openbare orde. In het Derde Boek vervalt Titel VI Overtredingen betreffende de zeden. 

Schuldaanranding, opzetaanranding, gekwalificeerde opzetaanranding, schuldverkrachting, opzetverkrachting en gekwalificeerde opzetverkrachting

In de nieuwe Titel Seksuele misdrijven wordt de strafrechtelijke bescherming tegen seksueel geweld verruimd. Deze verruiming is ingegeven door verschillende in hoofdstuk 2 van deze memorie beschreven maatschappelijke, juridische en technologische ontwikkelingen die noodzaken tot verlaging van de drempel voor strafbaarheid wegens aanranding en verkrachting. De huidige delicten aanranding en verkrachting worden vervangen door nieuwe delicten. Het betreft vier delicten met zes verschillende kwalificaties: schuldaanranding, opzetaanranding, gekwalificeerde opzetaanranding, schuldverkrachting, opzetverkrachting en gekwalificeerde opzetverkrachting. De strafrechtelijke aansprakelijkheid wordt aangescherpt. Bij de schulddelicten ontstaat strafbaarheid als seksuele handelingen worden verricht terwijl iemand ernstige reden heeft om te vermoeden dat bij de ander de wil hiertoe ontbreekt. Bij de opzetdelicten is iemand strafbaar als diegene seksuele handelingen verricht in de wetenschap dat de wil van de ander daartoe ontbreekt. Het gebruik van dwang, geweld of bedreiging is geen voorwaarde voor strafrechtelijke aansprakelijkheid, maar een strafverzwarende delictsvorm van de opzetdelicten.  Met deze nieuwe delicten wordt in de strafwet uitdrukking gegeven aan de sociale norm dat seks vrijwillig en gelijkwaardig behoort te zijn en dient te berusten op wederzijds goedvinden. Waar de huidige delicten aanranding en verkrachting uitgaan van strafbaarheid bij het ‘doorbreken’ van de wil van de ander, vangt zij in de nieuwe delicten aan bij het ontbreken van de wil bij de ander tot seksueel contact. Een “nee” is een “nee”. Als hieraan geen gehoor wordt gegeven, worden grenzen overschreden en is sprake van strafwaardig gedrag. Dit is ook het geval als een “nee” niet helder wordt uitgesproken, maar de ontbrekende wil wel uit de uiterlijk waarneembare feiten of omstandigheden kan worden afgeleid. 

De nieuwe opzet van de delicten betekent niet dat elk verrichten van seksuele handelingen zonder expliciete, vooraf gevraagde goedkeuring strafbaar wordt. Evenmin wordt iedere onbeantwoorde flirt, onhandige date of spijt achteraf strafbaar. Voorwaarde voor strafrechtelijke aansprakelijkheid is dat iemand een verwijt van zijn handelen of nalaten kan worden gemaakt. Hiervoor is allereerst vereist dat op het moment dat de seksuele handelingen plaatsvinden de wil daartoe bij de ander ontbreekt. Wanneer er op dat moment voor ieder weldenkend mens duidelijk waarneembare signalen zijn dat de wil tot seksueel contact bij die ander ontbreekt en de verdachte het seksueel contact toch aanvangt of voortzet, kan sprake zijn van een opzettelijk handelen (opzetvariant van aanranding of verkrachting) of van zeer onachtzaam handelen (schuldvariant van aanranding of verkrachting). De opzetvariant doet zich voor indien de verdachte daadwerkelijk weet dat de wil tot seksueel contact bij de ander ontbreekt (‘vol’ opzet) of zich bewust is van de mogelijkheid van een ontbrekende wil bij de ander en die mogelijkheid eenvoudigweg voor lief heeft genomen (voorwaardelijk opzet). Die laatste wezenlijk onverschillige mentale houding kenmerkt de ondergrens van het vereiste opzet. 

Kort gezegd heeft de ‘opzetdader’ geen boodschap aan de wil van de ander. Van de schuldvariant is sprake indien de verdachte die duidelijk waarneembare aanwijzingen voor een ontbrekende wil helemaal verkeerd heeft ingeschat door – in plaats van af te zien van (het voortzetten van) seksueel contact met die ander of eerst op toereikende wijze te verifiëren of het seksuele contact (nog steeds) op vrijwillige basis plaatsvindt – er ten onrechte van uit te gaan dat de wil tot seksueel contact bij die ander aanwezig is. Deze schuldvariant kenmerkt zich door de verwijtbare ernstige onachtzaamheid waarvan zo’n verkeerde inschatting getuigt. 

De nieuwe delicten vormen een nationaalrechtelijke omzetting van artikel 36 van het Verdrag van Istanbul. Dit verdragsartikel verplicht tot strafbaarstelling van seks zonder wederzijds goedvinden en schrijft voor dat de toestemming vrijwillig dient te zijn gegeven en dient voort te vloeien uit de vrije wil, hetgeen moet worden vastgesteld in het licht van de omstandigheden. Net als in het verdrag staan in de nieuwe delicten de instemming van de ander (consent) en de wilsvrijheid bij de ander centraal. Dit wordt uitgedrukt in de delictsomschrijvingen via het centrale bestanddeel ontbrekende wil. Hieronder wordt verstaan de afwezigheid van een vrije positieve wilsuiting. Bij de beoordeling van de vraag of sprake is van een ontbrekende wil bij de ander zijn de omstandigheden waaronder het seksuele contact plaatsvindt bepalend. Niet alleen de initiator van het seksuele contact, maar ook de ander dient hetzij verbaal, hetzij door een duidelijke non- verbale responsieve opstelling blijk te geven van de aanwezigheid van een positieve wilsuiting ter zake. 


Een van de basisdelicten is opzetverkrachting. Onder de kwalificatie van opzetverkrachting wordt strafbaar gesteld het verrichten van seksuele handelingen die bestaan uit of mede bestaan uit het seksueel binnendringen van het lichaam in de wetenschap dat de wil van de ander hiertoe ontbreekt. Nieuw is dat deze delictsgedraging - en daarmee de kwalificatie van de strafbare gedraging als verkrachting - niet meer is beperkt tot handelingen die bestaan uit of mede bestaan uit het ondergaan van het seksueel binnendringen van het lichaam, maar zich uitstrekt tot alle vormen van seksueel binnendringen waarbij het lichaam van een slachtoffer is betrokken. Dit betreft ook seksuele penetratie van het eigen lichaam of van het lichaam van een ander, al dan niet op afstand (bijvoorbeeld via een webcam). 

Bij opzetverkrachting is het strafrechtelijk verwijt jegens degene die deze delicten begaat dat diegene het seksuele contact initieert terwijl diegene ten tijde van dat contact wetenschap heeft van een ontbrekende wil bij de ander. Daarvan is sprake indien diegene daadwerkelijk weet dat de wil hiertoe bij de ander ontbreekt (‘vol’ opzet), maar ook indien diegene zich bewust is van de mogelijk ontbrekende wil van de ander en die mogelijkheid negeert of, in andere woorden, voor lief of op de koop toe neemt (voorwaardelijk opzet). Die laatste wezenlijk onverschillige mentale houding kenmerkt de ondergrens van het hier vereiste opzet. 

Van wetenschap van een ontbrekende wil bij de ander in de hiervoor bedoelde zin is in het algemeen sprake als die ander met duidelijke verbale of non-verbale signalen te kennen geeft het seksuele contact niet op prijs te stellen en de initiator zet het contact toch voort. Bijvoorbeeld als de ander zegt ‘nee’, ‘ik wil dit niet’, of ‘niet doen’ of bij gebruik van woorden van gelijke strekking. Of als de ander via duidelijke non-verbale signalen blijk geeft niet in te stemmen, zoals huilen, schreeuwen, het wegduwen van de initiator of het wegdraaien van het eigen lichaam. Wanneer de ander een dergelijke overduidelijke negatieve opstelling inneemt omtrent het seksuele contact en de initiator dit contact vervolgens toch doorzet, dan kan het niet anders dan dat die initiator weet dat bij de ander een positieve wilsuiting afwezig is en dit eenvoudigweg negeert. 

Wetenschap van een ontbrekende wil bij de ander kan er ook zijn bij een passieve opstelling van de ander in reactie op de seksuele toenadering. Bijvoorbeeld als de ander wegdraait, niet beweegt, zich stil houdt of lichamelijk bevriest of verstijft. Wanneer de ander bij seksuele toenadering geen teken van fysieke interactie vertoont en de initiator zet het seksuele contact toch voort, dan moet als regel worden aangenomen dat de initiator zich ervan bewust is dat een positieve wilsuiting bij de ander afwezig is en dit negeert dan wel voor lief neemt. 

Wetenschap van afwezigheid van een vrije positieve wilsuiting, en daarmee opzet, is er in het algemeen ook bij het verrichten van seksuele handelingen met een ander die in een toestand van geestelijke of lichamelijke onmacht verkeert. Dan wordt het onvermogen tot vrije wilsuiting bij de ander bewust genegeerd door degene die het seksuele contact initieert. Hierbij kan het gaan om zowel een in duur beperkte als een langdurige staat van geestelijk of lichamelijk onvermogen. In de eerste categorie valt te denken aan het in een toestand van slaap of bewusteloosheid verkeren of het in een roes verkeren als gevolg van het gebruik van alcohol en/of drugs. In de tweede categorie valt de aanwezigheid van een stoornis, handicap of aandoening als gevolg waarvan de ander niet of niet volledig in staat is tot wilsuiting omtrent het seksuele contact. 

De opzetvariant van verkrachting omvat ook de gevallen waarin sprake is van onverhoeds handelen. Het totaal onverwachts iemand op seksuele wijze betasten getuigt van opzettelijk handelen; de dader is zich bewust van de aantasting van de seksuele integriteit en wil de ander niet de ruimte geven om daarover zijn of haar wil te uiten. De opzetvariant doet zich eveneens voor in situaties waarin de initiator de wilsonvrijheid bij de ander zelf heeft veroorzaakt met gebruikmaking van dwang, geweld of bedreiging. Onder dergelijke omstandigheden kan niet anders geconcludeerd worden dan dat de dader bewust handelt en de wilsonvrijheid bij de ander heeft gewild. Bij gebruikmaking van dwang, geweld of bedreiging is sprake van een gekwalificeerde delictsvorm (gekwalificeerde opzetaanranding of gekwalificeerde opzetverkrachting). Met deze gekwalificeerde delictsvormen wordt het ernstiger verwijt dat de schuldige wordt gemaakt vanwege deze extra strafwaardige begeleidende omstandigheden tot uitdrukking gebracht. 

Wetenschap van een ontbrekende wil in de hiervoor bedoelde zin kan eveneens aan de orde zijn bij seksueel contact in een ongelijkwaardige verhouding tussen betrokkenen. Die situatie kan er in gelegen zijn dat de initiator van het seksuele contact een hiërarchische of vertrouwenspositie heeft ten opzichte van de ander. Bij het bestaan van een juridische gezagsrelatie of professionele hulpverlenings- of zorgrelatie tussen betrokkenen is de afhankelijkheidspositie van de ander in beginsel gegeven (zie nader de artikelsgewijze toelichting op de artikelen 240-244). Aangenomen moet worden dat iemand in een dergelijke hoedanigheid zich bewust is van de invloed van zijn of haar positie op het vermogen van de ander tot vrije wilsuiting, ook met betrekking tot het seksuele contact. Als binnen een dergelijke functionele afhankelijkheidsrelatie dan toch seksueel toenadering wordt gezocht dan zal dit in de regel plaatsvinden in de laakbare wetenschap dat bij die ander de wilsbepaling onder (sterke) invloed staat van (de wil van) de initiator en kan aldus van een vrije wilsuiting geen sprake zijn. 

De ruime reikwijdte van het nieuwe delict opzetverkrachting maakt strafbepalingen die specifieke strafrechtelijke bescherming bieden aan personen die in een staat van geestelijke of lichamelijke onmacht verkeren overbodig. De huidige hierop betrekking hebbende zelfstandige strafbaarstellingen keren niet terug in de nieuwe titel. De huidige strafbaarstelling die het verrichten van seksuele handelingen jegens functioneel afhankelijken met misbruik van gezag of misbruik van een vertrouwenspositie strafbaar stelt keert evenmin in een zelfstandige vorm terug. Het gaat hier om gevallen van onvrijwillig seksueel contact, voortvloeiend uit een ongelijkwaardige situatie, die reeds worden bestreken door de ruime reikwijdte van de nieuwe basisdelicten. Met dien verstande dat degene die de feiten begaat in een bijzondere hiërarchische of vertrouwelijke relatie staat tot het slachtoffer. Dat betrokkene misbruik maakt van zijn gezag of vertrouwenspositie bij het plegen van deze delicten moet diegene zwaarder worden aangerekend. Die extra strafwaardigheid krijgt vorm in een aanvullende strafverzwaringsgrond. 


In aanvulling op de nieuwe delicten opzetaanranding, gekwalificeerde opzetaanranding, opzetverkrachting en gekwalificeerde opzetverkrachting introduceert dit wetsvoorstel de delicten schuldaanranding en schuldverkrachting. De delictsomschrijvingen bevatten in beginsel dezelfde bestanddelen als de basisdelicten opzetaanranding en opzetverkrachting, maar in plaats van bij wetenschap van de ontbrekende wil (‘vol’ of voorwaardelijk opzet) ligt de ondergrens voor strafrechtelijke aansprakelijkheid bij de ernstige reden om te vermoeden dat de wil tot seksueel contact bij de ander ontbreekt. 

Deze strafrechtelijke ondergrens weerspiegelt de sociale norm dat seks vrijwillig en gelijkwaardig behoort te zijn en koppelt strafrechtelijke aansprakelijkheid in belangrijke mate aan het bij wederkerigheid van seksueel contact behorende besef van en verantwoordelijkheid voor het eigen handelen en bewustzijn van het gedrag van een ander. De nieuwe schulddelicten bieden slachtoffers strafrechtelijke bescherming tegen zeer onachtzaam en daardoor zeer laakbaar seksueel gedrag van anderen. Hiermee wordt verzekerd dat de strafrechtelijke beoordeling van de afwezigheid van een vrije positieve wilsuiting bij de ander kan plaatsvinden in het licht van de relevante omstandigheden, conform het uitgangspunt in artikel 36 van het Verdrag van Istanbul. 

De nieuwe delicten hebben gevolgen voor de bewijsvoering. Voor een veroordeling voor schuldaanranding, opzetaanranding, schuldverkrachting of opzetverkrachting is niet nodig dat er bewijs is van dwang. Er hoeft dus niet meer bewezen te worden dat een slachtoffer zich heeft verzet of zich niet kon onttrekken aan de seksuele handelingen. 

In verband met die bewijsvoering moet verder voor ogen worden gehouden dat dit wetsvoorstel slechts tot op zekere hoogte antwoord kan geven op de vraag of in een concreet geval sprake is van ofwel de opzetvariant (van aanranding of verkrachting) ofwel de zware schuldvariant. Dat houdt ermee verband dat het onderscheid tussen opzet en zware schuld bestaat uit een verschil in de mentale houding van de verdachte ten aanzien van het ontbreken van de wil bij de ander. Wanneer verklaringen van de verdachte of van getuigen geen inzicht geven over wat destijds in de verdachte is omgegaan, dan zal de rechter diens vaststellingen daarover in de kern moeten baseren op de uiterlijke verschijningsvorm van de (interactie tussen) gedragingen van de verdachte en gedragingen van de ander alsook de overige omstandigheden waaronder die gedragingen zijn verricht en wat daaruit naar algemene regels valt af te leiden. Mede in het licht van die context kunnen bepaalde gedragingen van de verdachte zo zeer wezenlijke onverschilligheid ten aanzien van de wil tot seksueel contact bij de ander uitdrukken, dat het – behoudens contra-indicaties – niet anders kan zijn dan dat de verdachte de mogelijkheid van een ontbrekende wil voor lief heeft genomen. Denk aan het doorzetten van seksueel contact nadat de ander ‘nee’ of ‘ik wil dit niet’ tegen diegene heeft gezegd. Het voor de opzetvariant vereiste opzet zal doorgaans op de hiervoor geschetste indirecte wijze moeten worden vastgesteld. Daarmee is onvermijdelijk dat zich in de praktijk op het vlak van de bewijsvoering moeilijke grensgevallen zullen gaan voordoen. Tegelijk heeft de schuldvariant juist ook een zekere vangnetfunctie voor gevallen van zeer laakbaar seksueel gedrag waarbij de rechter van oordeel is dat opzettelijk handelen niet is bewezen; ook dan is voorzien in een adequate vorm van strafrechtelijke bescherming. 

Hoewel de drempel voor strafbaarheid in de schuld- en opzetvarianten van aanranding en verkrachting lager ligt dan bij de huidige dwangdelicten, is voorstelbaar – zo bleek hiervoor al – dat het bewijs in een concrete zaak soms toch lastig te leveren zal zijn. Gezien de aard van deze seksuele misdrijven, die zich dikwijls voordoen in een een-op-eensituatie zonder aanwezigheid van getuigen, kan het vergaren van voldoende bewijsmiddelen ingewikkeld zijn en blijven. 

Bij onvoldoende bewijs volgt een sepot of een vrijspraak. Hierbij dient in aanmerking te worden genomen dat de inzet van het strafrecht weliswaar een belangrijk instrument, maar niet het enige instrument is in de brede multidisciplinaire aanpak van seksueel grensoverschrijdend gedrag. Niet ieder slachtoffer doet aangifte van een strafbaar feit. Soms kiest een slachtoffer voor een alternatief traject, dat in het individuele geval een andere of betere oplossing biedt, zoals herstelbemiddeling, schadevergoeding, een straatverbod of hulpverlening. 

De nieuwe strafmaxima voor verkrachting lopen in de pas met de strafmaxima in een aantal landen die, al dan niet recent, alle vormen van onvrijwillige seks in de strafwet als verkrachting hebben gekwalificeerd. Zo bedragen de maximale straffen in Zweden voor schuldverkrachting, opzetverkrachting en gekwalificeerde verkrachting respectievelijk vier, zes en tien jaren gevangenisstraf. In België is de maximumstraf voor verkrachting tien jaren gevangenisstraf. In Duitsland geldt een lagere maximumstraf van vijf jaren gevangenisstraf. 

De nieuwe wettelijke strafmaxima voor de verschillende delictsvormen van aanranding en verkrachting stellen het OM en de rechtspraak in staat een bij de aard en ernst van de strafbare gedraging passende straf te bepalen. 

Met de nieuwe strafbaarstellingen wordt een balans gevonden tussen bestraffing van strafbaar gedrag die enerzijds recht doet aan de beleving van slachtoffers en anderzijds is toegesneden op de mate van verwijtbaarheid aan de zijde van degene die het delict pleegt. Hierdoor wordt de strafrechtketen in staat gesteld zaken gericht op te pakken. Het OM en de rechter kunnen bij de tenlastelegging, strafeis en de strafoplegging rekening houden met de omstandigheden waaronder het delict is gepleegd en het specifieke verwijt dat een dader kan worden gemaakt. Zo is onder meer het taakstrafverbod niet van toepassing op de delicten schuldaanranding en schuldverkrachting. Maatwerk bij vervolging en bestraffing is in de praktijk van belang, bijvoorbeeld in situaties waarin sprake is van experimenteergedrag tussen jongvolwassenen. 

Niet uit te sluiten valt dat het in de praktijk - en zeker in de eerste periode na inwerkingtreding van de nieuwe wetgeving - soms lastig kan zijn te bepalen of sprake is van een strafbare gedraging en van welke delictsvorm. Seksueel grensoverschrijdend gedrag kan zich in vele variaties voordoen. De rol van het strafrecht bij ongewenst seksueel contact is niet onbegrensd. Seksueel contact is naar zijn aard intiem en speelt zich veelal af in de privé-omgeving. Seksuele communicatie is subtiel en zeker in de beginfase van het contact worden over en weer grenzen verkend. De precieze afbakening van de strafrechtelijke normstelling zal dan ook aan de hand van concrete casusposities die zich aandienen nader vorm moeten krijgen. 
Het duurt vermoedelijk enige tijd voordat de rechtsontwikkeling op dit punt is uitgekristalliseerd. Hierbij is een belangrijke rol weggelegd voor de rechtspraktijk. Zo is het voorstelbaar dat (zeker in de beginfase) in grensgevallen verschillende delictsvormen (primair dan wel (meer) subsidiair) ten laste worden gelegd. Als een ernstiger delictsvorm niet bewezen kan worden verklaard, dan kan – bij de aanwezigheid van voldoende bewijs – veroordeling voor een minder ernstige variant volgen. 

Verhouding tot hoger recht
Seksueel geweld vormt een ernstige aantastingen van het recht op eerbiediging van de persoonlijke levenssfeer en het recht op de onaantastbaarheid van de lichamelijke en geestelijke integriteit. De bescherming van deze grondrechten wordt zowel op nationaal niveau - in de Grondwet - als op internationaal niveau - in het Handvest van de grondrechten van de Europese Unie en EVRM - geborgd. 

Artikel 10, eerste lid, van de Grondwet, waarborgt het recht op eerbiediging van de persoonlijke levenssfeer. In artikel 11 van de Grondwet staat het recht op onaantastbaarheid van het lichaam centraal. Artikel 8 van het EVRM beschermt onder meer het recht op respect voor een privéleven en een familie- en gezinsleven en artikel 3 bepaalt dat niemand mag worden onderworpen aan foltering of aan onmenselijke of vernederende behandeling of bestraffing. Artikel 1 van het Handvest van de grondrechten van de Europese Unie bepaalt dat de menselijke waardigheid onschendbaar is; zij moet worden geëerbiedigd en beschermd. Artikel 3 betreft het recht op lichamelijke en geestelijke integriteit. De gelding van het Handvest is voor de EU-lidstaten beperkt tot gevallen waarin zij het recht van de Unie tot uitvoer brengen (artikel 51 Handvest). 
Uit het arrest M.C. t. Bulgarije van het Europese Hof voor de Rechten van de Mens (EHRM) volgt dat staten een positieve verplichting hebben op grond van de artikelen 3 en 8 van het EVRM om strafrechtelijke maatregelen te nemen die effectieve bestraffing van verkrachting mogelijk maken en deze toe te passen in de praktijk.56 Uit het arrest X&Y tegen Nederland en uit latere arresten kan worden afgeleid dat de artikelen 3 en 8 van het EVRM staten verplichten tot het nemen van strafrechtelijke maatregelen ter bescherming van burgers tegen seksueel geweld, ook wanneer dit veroorzaakt is door medeburgers.57 Deze maatregelen moeten leiden tot effectieve bescherming, in het bijzonder van kinderen en andere kwetsbare personen. Uit de jurisprudentie van het EHRM volgt dat de inzet van het strafrecht geboden is bij verkrachting, seksueel misbruik van kinderen, of een anderszins ‘non-consensual sexual act’. In de zaak Söderman tegen Zweden heeft het EHRM hieraan toegevoegd dat ook minder ernstige gedragingen die een potentiële bedreiging vormen voor het lichamelijk en geestelijk welzijn van een minderjarige strafbaar gesteld dienen te worden. Voor een effectieve bescherming tegen minder ernstige gedragingen die mogelijk de psychische integriteit van een minderjarige schenden kunnen ook andere dan strafrechtelijke maatregelen volstaan.58 
Artikel 10 van het EVRM beschermt het recht op vrije meningsuiting. Strafbaarstelling van seksuele intimidatie kan een beperking vormen van het recht op vrije meningsuiting, omdat bepaalde uitlatingen niet meer vrijelijk kunnen worden gedaan. Het recht op vrije meningsuiting is echter niet absoluut: op grond van het tweede lid van artikel 10 van het EVRM kan een beperking gerechtvaardigd zijn indien die is voorzien bij wet. Deze wettelijke grondslag moet voldoen aan de vereisten van toegankelijkheid (accessibility) en voorzienbaarheid (foreseeability). Voorts dient de beperking een legitiem doel te dienen en noodzakelijk te zijn in een democratische samenleving. Bij dit laatste vereiste is relevant of er een dringende maatschappelijke behoefte (pressing social need) bestaat.59 
Het derde lid van artikel 7 van de Grondwet bepaalt dat niemand voorafgaand verlof nodig heeft voor het openbaren van gedachten en gevoelens door andere middelen dan de drukpers en omroep, behoudens ieders verantwoordelijkheid volgens de wet. Dit recht impliceert het recht een mening te uiten, waarbij alleen de wet in formele zin beperkingen kan stellen. Ook uitingen op internet vallen onder de reikwijdte van deze bepaling. Voorafgaand verlof wegens de inhoud van een uiting is verboden, ook als dat bij wet in formele zin geschiedt.60 Waar een (repressieve) beperking wordt aangebracht, moet bovendien zijn voorzien in een rechtsgang naar een rechtsprekend orgaan. 
Het in de artikelen 7 van het EVRM, 16 van de Grondwet en 1 Sr verwoorde legaliteitsbeginsel brengt mee dat uitsluitend de wet bepaalt welke gedragingen strafbaar zijn. Volgens de rechtspraak van het EHRM moet de wetgeving voldoende toegankelijk, nauwkeurig en voorzienbaar zijn. Om tot succesvolle strafvervolging over te kunnen gaan, dienen delictsomschrijvingen voldoende bepaald te zijn. 

4.2 Internationale verplichtingen 

Verschillende internationale verdragen bieden bescherming tegen seksueel grensoverschrijdend gedrag. Het eerdergenoemde Verdrag van Istanbul biedt bescherming tegen diverse vormen van geweld, waaronder seksueel geweld. Artikel 36, eerste lid, van het verdrag verplicht tot het nemen van wetgevende of andere maatregelen die nodig zijn om te waarborgen dat de volgende opzettelijke gedragingen strafbaar worden gesteld: a. het zonder wederzijds goedvinden op seksuele wijze vaginaal, anaal of oraal met een lichaamsdeel of object binnendringen van het lichaam van een ander;
b. het zonder wederzijds goedvinden plegen van andere seksuele handelingen met een persoon; c. het zonder wederzijds goedvinden een ander ertoe bewegen seksuele handelingen te plegen met een derde.
Ingevolge het tweede lid dient de toestemming vrijwillig te zijn gegeven en voort te vloeien uit de vrije wil van de betrokken persoon hetgeen dient te worden vastgesteld in het licht van de omstandigheden. Het derde lid verplicht tot het nemen van wetgevende of andere maatregelen die nodig zijn om te waarborgen dat de bepalingen van het eerste lid tevens van toepassing zijn op gedragingen gericht tegen voormalige of huidige echtgenoten of partners erkend volgens het nationale recht. Artikel 40 van het verdrag schrijft het nemen van wetgevende of andere maatregelen voor die nodig zijn om te waarborgen dat elke vorm van ongewenst verbaal, non- verbaal of fysiek seksueel getint gedrag met het doel of gevolg de waardigheid van een persoon te schenden, in het bijzonder door het creëren van een intimiderende, vijandige, onterende, vernederende of beledigende omgeving, is onderworpen aan strafrechtelijke of andere juridische sancties. Het verdrag is op 1 maart 2016 in werking getreden voor Nederland. 

Artikel 34 van het Internationaal Verdrag inzake de rechten van het kind (IVRK) biedt kinderen bescherming tegen alle vormen van seksueel misbruik en seksuele exploitatie. De internationale dimensie van seksueel misbruik en seksuele uitbuiting van kinderen maakt dat voor een effectieve preventie en bestrijding intensieve internationale samenwerking, op basis van internationaal bindende instrumenten, noodzakelijk is.61 Hieraan wordt invulling gegeven door het Verdrag van de Raad van Europa inzake de bescherming van kinderen tegen seksuele uitbuiting en seksueel misbruik (Verdrag van Lanzarote)62 en de richtlijn 2011/93/EU. 

Het Verdrag van Lanzarote voorziet in een alomvattende en multidisciplinaire aanpak van seksueel misbruik en seksuele uitbuiting. Het verdrag verplicht tot strafbaarstelling van verschillende vormen daarvan. Naast seksueel misbruik betreft het ook het benaderen van kinderen voor seksuele doeleinden (artikelen 18 tot en met 23). Het verdrag verplicht ertoe dat op de strafbare gedragingen doeltreffende, evenredige en afschrikwekkende straffen worden gesteld met inachtneming van de ernst van de feiten. Het verdrag verplicht te voorzien in de mogelijkheid tot het opleggen van hogere straffen in een aantal strafverzwarende omstandigheden (artikel 28). De vervolging mag niet afhankelijk zijn van aangifte of een klacht (artikel 32). 

De richtlijn 2011/93/EU brengt de EU-wetgeving op het terrein van bescherming van kinderen tegen seksueel misbruik en seksuele uitbuiting, waaronder kinderpornografie, op het niveau van het Verdrag van Lanzarote. De richtlijn vindt haar grondslag in de artikelen 82, tweede lid, en 83, eerste lid, van het Verdrag betreffende de werking van de Europese Unie (VWEU). Deze artikelen bieden de grondslag voor rechtsinstrumenten op het terrein van de justitiële samenwerking in strafzaken, waaronder het vaststellen van minimumvoorschriften met betrekking tot de rechten van personen in de strafvordering en de bepaling van strafbare feiten en sancties. In artikel 83, eerste lid, VWEU wordt seksuele uitbuiting van kinderen expliciet genoemd als een vorm van criminaliteit waarvoor bij richtlijn minimumvoorschriften kunnen worden vastgesteld. 
De richtlijn hanteert het begrip seksuele meerderjarigheid (age of consent) en geeft daarvan een definitie: de leeftijd waar beneden het - overeenkomstig de eigen nationale wetgeving - niet is toegestaan seksuele handelingen aan te gaan met een kind (artikel 2). Artikel 3 bevat een verplichting tot strafbaarstelling van verschillende vormen van seksueel misbruik. De artikelen 4 en 5 stellen seksuele uitbuiting en kinderpornografie strafbaar. Het benaderen van kinderen voor seksuele doeleinden wordt op vergelijkbare wijze als in het Verdrag van Lanzarote strafbaar gesteld (artikel 6). De richtlijn houdt rekening met normaal seksueel verkeer tussen jongeren, inhoudende consensuele seksuele handelingen tussen gelijken (artikel 8). Opsporing en vervolging van strafbare feiten mogen niet afhankelijk zijn van een klacht of aangifte (artikel 15). 

4.3 Verhouding van het wetsvoorstel tot hoger recht 
Dit wetsvoorstel biedt strafrechtelijke bescherming tegen seksuele misdrijven. Hiermee wordt voldaan aan de uit de EHRM-rechtspraak voortvloeiende verplichting om via het strafrecht bescherming te bieden tegen gedragingen die betrekking hebben op seksueel geweld, zoals verkrachting.

De nieuwe strafbaarstelling van opzetverkrachting is in lijn met artikel 36 van het Verdrag van Istanbul, dat verplicht tot strafbaarstelling van seksuele handelingen zonder wederzijds goedvinden. Uit verschillende landenrapportages van GREVIO komt naar voren dat GREVIO erop let of de afwezigheid van toestemming op zodanige wijze wordt omschreven dat de mogelijkheid om rekening te houden met alle omstandigheden niet wordt beperkt. 
De formulering van de strafbaarstellingen van de verschillende delictsvormen van aanranding en verkrachting bieden hiervoor de ruimte. De strafrechtelijke aansprakelijkheid is daarbij gekoppeld aan het weten of ernstige reden hebben om te vermoeden dat seksuele handelingen worden verricht terwijl de wil bij de ander daartoe ontbrak. Zoals hierboven al uiteengezet gaat het niet alleen om expliciete verbale signalen en gedrag van de ander (een ‘nee’) maar ook om feiten en omstandigheden die aanleiding hadden moeten zijn voor de constatering dat bij de ander mogelijk een positieve wil ontbrak met betrekking tot het seksuele contact. Met de zelfstandige strafbaarstelling van indringende seksuele benadering als overtreding tegen de openbare orde wordt gevolg gegeven aan artikel 40 van voornoemd verdrag dat verdragspartijen oproept sancties te verbinden aan seksuele intimidatie.

De strafrechtelijke bescherming tegen seksueel misbruik krijgt in dit wetsvoorstel vorm binnen de kaders van het Verdrag van Lanzarote en de richtlijn 2011/93/EU. Het wetsvoorstel neemt het materiële beschermingsniveau zoals dit na implementatie van het verdrag en de richtlijn in de huidige zedentitel tot stand is gebracht tot uitgangspunt.63 Strafbaar gesteld worden de in de artikelen 3 tot en met 6 van de richtlijn 2011/93/EU genoemde opzettelijke gedragingen. De door de richtlijn voorgeschreven minimale maximumstraffen voor deze strafbare feiten worden telkens in acht genomen. Hetzelfde geldt ten aanzien van de in artikel 9 van de richtlijn genoemde strafverzwarende omstandigheden. 

5. Gevolgen 
De nieuwe wetgeving verbetert de strafrechtelijke bescherming tegen verschillende vormen van seksueel grensoverschrijdend gedrag. Hierdoor wordt de veiligheid van mensen vergroot. Als gevolg van de nieuwe wetgeving krijgen de organisaties in de strafrechtketen meer mogelijkheden om op te treden tegen strafwaardig gedrag. Een actueel wettelijk kader maakt het mogelijk om beter in te spelen op bestaande, nieuwe en toekomstige delictsvormen. Dit zal naar verwachting bijdragen aan een effectievere opsporing, vervolging, berechting en bestraffing van strafbare feiten en het voorkomen van recidive. De voorgestelde wettelijke structuur is ondersteunend aan het werk van rechercheur en officier van justitie. Voor politie en OM wordt het door beter op veelvoorkomende gedragingen toegesneden strafbaarstellingen naar verwachting eenvoudiger om gedragingen te identificeren, op ernst te selecteren en te vervolgen. De uitbreiding van de strafwet met strafbaarstellingen van schuldaanranding, opzetaanranding, schuldverkrachting en opzetverkrachting, van sexchatting met kinderen en van seksuele intimidatie en de verduidelijking van verschillende bestaande strafbaarstellingen op bestanddeelniveau hebben tot gevolg dat de drempel om tot bewezenverklaring te komen wordt verlaagd. Dit biedt ruimere mogelijkheden om strafrechtelijk op te treden tegen strafwaardige gedragingen, zij het dat inherent blijft aan seksuele misdrijven – die zich dikwijls voordoen in een een-op-een situatie zonder aanwezigheid van getuigen – dat het bewijs in een concrete zaak soms lastig te leveren zal zijn. 

Als gevolg van de nieuwe wetgeving verbetert de positie van slachtoffers. Zij krijgen meer mogelijkheden om aangifte te doen van een strafbaar feit. De professionals die slachtoffers ondersteunen en begeleiden kunnen aan de hand van het wettelijk kader aan slachtoffers beter duidelijk maken voor welke strafbare feiten een dader vervolgd en berecht kan worden. Dit kan bijdragen aan de verhoging van de aangiftebereidheid van slachtoffers.

zelf melding te doen van strafbaar gedrag of gedrag dat potentieel hierin kan resulteren. 
Het nieuwe wettelijke kader heeft tot gevolg dat het risico op strafrechtelijke aansprakelijkheid beter voorzienbaar wordt voor (potentiële) daders. Heldere strafrechtelijke normen geven een duidelijk signaal af naar (potentiële) daders dat seksueel grensoverschrijdend gedrag niet acceptabel is en streng wordt bestraft. Aangenomen wordt dat dit een afschrikwekkend effect heeft en bijdraagt aan een positieve gedragsverandering. Bij normoverschrijding kan strafrechtelijk worden ingegrepen en kan passende bestraffing plaatsvinden. Daders van ernstige seksuele misdrijven kunnen voor langere tijd worden gedetineerd. Naast straffen kunnen ook persoonsgerichte maatregelen worden opgelegd, waarmee het recidiverisico kan worden verlaagd. 

De nieuwe wetgeving maakt meer maatwerk bij de afdoening van zaken mogelijk. Omdat er meer verschillende delicten en delictsvormen komen kan beter worden ingespeeld op het type gedrag dat ten grondslag ligt aan de strafbare gedraging. In een vroegtijdig stadium van strafrechtelijk optreden kan worden geselecteerd welk delictsgedrag zich voordoet en welk strafrechtelijk verwijt de dader toekomt. Bij de keuze voor vervolging voor een bepaald delict kan een relatie worden gelegd met de aanwezigheid van risicofactoren voor het plegen van een nieuw delict. Om inzicht te krijgen in de aard en ernst van het gedrag kan in het kader van het strafrechtelijk onderzoek een risico-inschatting worden verricht, waarna een adequate en effectieve wijze van afdoening kan worden gekozen. In het kader van het voorkomen van recidive kan bij de sanctionering worden gekozen voor een persoonsgerichte aanpak, waarbij ook acht wordt geslagen op de context waarin een misdrijf wordt gepleegd, zoals de jeugdige leeftijd van de dader. 

Met het taakstrafverbod voor seksuele misdrijven wordt sturing gegeven in de sanctionering van ernstige zaken. Bij de berechting van volwassenen wordt voor een kale taakstraf een onvoorwaardelijke gevangenisstraf in de plaats gesteld. Onder het jeugdstrafrecht zijn er meer combinatiemogelijkheden. Het is aan de rechter de duur van de straf te bepalen. De rechter heeft een ruime straftoemetingsvrijheid en kan steeds per individueel geval maatwerk leveren en een passende straf opleggen. 

De nieuwe wetgeving legt een bijzondere verantwoordelijkheid op alle bij de opsporing en vervolging van seksuele misdrijven betrokken organisaties om zorgvuldig en professioneel op te treden met oog voor de belangen van slachtoffers en voor de bescherming van de rechten van de verdachte. In zedenzaken staat er zowel voor slachtoffers als voor verdachten veel op het spel. Slachtoffers krijgen na een ingrijpende gebeurtenis in hun leven te maken met een strafrechtelijk onderzoek waarin ze worden onderworpen aan indringende verhoren en onderzoeken over intieme onderdelen van hun leven. Inzet van het strafrecht kan bijdragen aan genoegdoening van het leed dat een slachtoffer is aangedaan en verwerking van een traumatische gebeurtenis. Tegelijkertijd moet gewaakt worden voor te hoge verwachtingen, omdat er altijd een risico is op gebrek aan bewijs, waardoor een aangifte kan stranden in een vrijspraak of een sepot. 
Ook voor een verdachte van een seksueel misdrijf staat er veel op het spel. Seksuele misdrijven roepen sterke negatieve gevoelens op binnen de samenleving. Dit soort misdrijven wordt veroordeelden zwaar aangerekend. Nadat een veroordeelde is afgestraft, kan het etiket “zedendelinquent” diegene nog lang maatschappelijk blijven achtervolgen. Maar ook voordat de schuld in rechte vast komt te staan kan de (vermeende) betrokkenheid bij een seksueel misdrijf al grote gevolgen hebben voor iemands leven. Onjuiste of valse aangiftes kunnen de reputatie van een beschuldigde schaden met alle gevolgen van dien. Zo kan een sepot wegens onvoldoende bewijs bijvoorbeeld ook zo worden uitgelegd dat het niet is gelukt om uit te sluiten dat een strafbaar feit is gepleegd.


De bijzondere en gevoelige aard van seksuele misdrijven vraagt in alle zaken om zorgvuldig en uitgebreid onderzoek naar de feitelijke gebeurtenissen. Dit stelt hoge eisen aan het professioneel handelen van alle bij het strafproces betrokken organisaties - de politie, het OM, de advocatuur en rechters. Op iedere professional rust vanuit ieders verantwoordelijkheid de plicht om in elke zaak alle relevante feiten en omstandigheden te onderzoeken, te wegen en te kwalificeren. 

De nieuwe wetgeving brengt tot uitdrukking welk gedrag binnen onze samenleving als strafwaardig seksueel (overlastgevend) gedrag wordt gezien. Hiervan gaat een duidelijke normerende werking uit. Heldere wettelijke normstelling draagt bij aan de maatschappelijke bewustwording over wat wel en niet geaccepteerd gedrag is. Tegelijk vergt het inscherpen van die bewustwording een breder offensief, waarbij niet alleen het (straf)recht een rol speelt, maar de gehele maatschappij is betrokken. Om een goede doorwerking van de nieuwe normstelling in het maatschappelijke verkeer te bewerkstelligen wordt in het kader van de implementatie van de nieuwe wetgeving), in aanvulling op lopende initiatieven, aandacht besteed aan maatschappelijke voorlichting en bewustwording over seksueel grensoverschrijdend gedrag en seksueel geweld, en waar de grenzen liggen tussen op wederzijdse instemming en wilsvrijheid gebaseerd, en strafbaar seksueel gedrag. Dit betreft in ieder geval - maar niet uitsluitend – de nieuwe delictsvormen van aanranding en verkrachting. Hierbij wordt aandacht besteed aan onderwerpen als het onderzoeken, herkennen, bespreken en respecteren van elkaars seksuele grenzen. 

In de adviezen van verschillende organisaties over het wetsvoorstel zijn waardevolle suggesties gedaan om de maatschappelijke inbedding van de sociale normen die aan de nieuwe wetgeving ten grondslag liggen, te realiseren. In het kader van de implementatie van de nieuwe wetgeving wordt in overleg met deze organisaties en andere relevante partijen bezien wat, aanvullend op lopende campagnes, nodig is voor brede maatschappelijke voorlichting over de nieuwe strafrechtelijke normstelling, bijvoorbeeld als het gaat om de invulling van het begrip ontbrekende wil. Daarbij zal aandacht worden besteed aan gendersensitiviteit en het voorkomen van victim blaming. In dit verband wordt aansluiting gezocht bij het nationaal actieplan tegen grensoverschrijdend seksueel gedrag en seksueel geweld waar het kabinet, onder coördinatie van de ministers van Sociale Zaken en Werkgelegenheid (SZW) en Onderwijs Cultuur en Wetenschap (OCW) en in samenwerking met de onlangs door het kabinet aangestelde regeringscommissaris seksueel grensoverschrijdend gedrag en seksueel geweld, aan werkt
Uitvoering en handhaving 

6.2 Opzetverkrachting en gekwalificeerde opzetverkrachting
Bij de nieuwe delictsvormen van verkrachting gaat het primair om de vraag wat de verdachte tijdens het seksueel contact wist of behoorde te weten over de ontbrekende wil bij de ander. De aanwezigheid van een dwangsituatie is een strafverzwarende omstandigheid. De uitbreiding van de strafrechtelijke aansprakelijkheid die wordt bewerkstelligd, leidt naar verwachting tot meer meldingen bij de politie. Ook is het de verwachting dat in toenemende mate een beroep wordt gedaan op het Centrum Seksueel Geweld (CSG), wat onder meer gevolgen heeft voor het werk van het Nederlands Forensisch Instituut (NFI) vanwege het uitvoeren van forensisch-medisch onderzoek en sporenonderzoek.

Meldingen van aanranding of verkrachting kunnen direct bij de politie worden gedaan of via het CSG, dat slachtoffers naar de politie doorgeleidt. Zeker indien binnen zeven dagen na het incident melding wordt gemaakt (acute zaken), speelt het CSG een belangrijke rol bij de eerstelijnshulpverlening aan het slachtoffer en de advisering ten aanzien van eventueel te nemen stappen, zoals het melden van het incident bij de politie en doorgeleiding naar de politie. Bij de politie volgt een informatief gesprek met het slachtoffer, waarin het slachtoffer wordt geïnformeerd over de te nemen stappen, de te volgen procedures en over de opsporingsmogelijkheden gericht op vervolging naar aanleiding van de verklaring van het slachtoffer. Na het informatief gesprek kunnen in het CSG een forensisch-medisch onderzoek en een sporenonderzoek worden uitgevoerd, waarbij het NFI een belangrijke rol speelt, zeker in het geval waarin slachtoffers minderjarig zijn. 

Naar verwachting gaat de uitbreiding van de strafrechtelijke aansprakelijkheid voor verkrachting vooral gepaard met een groei van het aantal meldingen en te behandelen zaken, aangezien slachtoffers door deze uitbreiding in meer gevallen aangifte bij de politie kunnen doen, maar niet met een (grote) wijziging in het werkproces van de betrokken partijen en de inrichting daarvan. Ten aanzien van de kwantitatieve impact geldt dat de politie in de aangeleverde impactanalyse in het hoogste scenario uitgaat van een stijging van 550 meldingen (+20%), 360 extra te voeren informatieve gesprekken (+20%) en 344 extra opsporingsonderzoeken (+50%). Voor het behandelen daarvan zijn naar de inschatting van de politie zo’n 15 extra fte vereist. Het NFI verwacht in de aangeleverde impactraming aan de hand hiervan een toename van het aantal uit te voeren DNA-onderzoeken, forensisch-medische onderzoeken, toxicologische onderzoeken en digitaal-forensische onderzoeken. Het CSG verwacht op grond van een eigen impactraming dat het aantal meldingen bij inwerkingtreding van deze wet met 25% stijgt, naast de reeds ingecalculeerde groei van het aantal meldingen van 25% die al was voorzien. Deze inschatting is gebaseerd op de inwerkingtreding van de gehele wet en strekt dus verder dan het effect dat alleen te verwachten is van de wijzigingen in de aanrandings- en verkrachtingsdelicten. De effecten van het CSG zijn voor het grootste deel gelegen in de extra zorgverlening, zowel in acute als niet-acute zaken. Ook wordt een toename in het gebruik van de landelijke bel- en chatfunctie van het CSG verwacht.
`

export default {
    name: 'Legal (NL)',
    key: 'legal_nlNL',
    prefill: 'Je naam is meester Kevin Strondtvoogel (uit Bruynstad). Je bent een behulpzame universitaire studie-assistent op het gebied van Strafrecht en Wetshandhaving, en spreekt Nederlands. De gebruiker spreekt zelf ook Nederlands, en dient ten alle tijden te worden voorzien van een behulpzaam, correct, en op feiten gebaseerd antwoord in correct Nederlands.\n\nWaar mogelijk en gewenst hanteer je een professionele manier van spreken, zoals je dat zou doen in een formele setting. Je helpt de gebruiker met het studeren van bepaalde casussen, welke gebruikt worden voor het schrijven van een scriptie over het nieuwe wetsvoorstel over seksueel misbruik (zie bijlage hieronder, data afkomstig van overheidswebsites). Let op: je kunt gebruik maken van Markdown voor tekstopmaak.\n\n===========\n' + PREFILL_1 + "\n\n===========\n\n" + PREFILL_3
};
